<template>
    <v-data-table
      :headers="headers"
      :items="entities"
      :items-per-page="10"
      :loading="isLoading"
      class="elevation-1"
    >
        <template v-slot:item.name="{ item }">
            <entity-popover :entity="item" />
        </template>
        <template v-slot:item.description="{ item }">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ truncateText(item.description) }}</span>
            </template>
            <span>{{ item.name }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.created_at="{ item }">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.created_at | formatRelativeDate }}</span>
            </template>
            <span>{{ item.created_at | formatDate }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.count="{ item }">
            <v-chip color="primary" small>{{ item.count }}</v-chip>
        </template>
        <template v-slot:item.occurrences="{ item }">
            <entity-occurrences
            :entityOccurrences="item.occurrences"
            :entity="item"
            />
        </template>
    </v-data-table>
</template>

<script>
import EntityPopover from '@/entity/EntityPopover.vue';
import EntityOccurrences from '@/entity/EntityOccurrences.vue';

export default {
    name: 'EntityTable',
    components: {
        EntityPopover,
        EntityOccurrences,
    },
    props: {
        inputEntities: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Created At', value: 'created_at'},
                { text: 'Count', value: 'count' },
                { text: 'Occurrences', value: 'occurrences' },
            ],
        };
    },
    methods: {
        truncateText(text) {
            if (text.length > 50) {
                return text.substring(0, 50) + '...';
            }
            return text;
        },
    },
    computed: {
        entities() {
            return this.inputEntities
        },
    },
};

</script>
