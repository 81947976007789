<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
      <v-row justify="center">
        <date-chip-group-relative
          class="pl-2 mt-2"
          label="Time Range"
          v-model="selectedDateTime"
          @input="onSelectedDateTimeChange"
        />
      </v-row>
      <v-row v-if="entity_types && entity_types.length >= 1" class="ml-10 mr-10">
        <v-col v-for="entity_type in entity_types" :key="entity_type.id" cols="4">
          <incident-entity-type-card
            :entity_type="entity_type"
            :count="entity_type.count"
            :selectedDateTime="selectedDateTime"
        />
        </v-col>
      </v-row>
      <div v-else>
        <p class="text-center">No IAOs available.</p>
      </div>
    </v-container>
  </template>

<script>
import IncidentEntityTypeCard from "@/entity_type/IncidentEntityTypeCard.vue"
import DateChipGroupRelative from "@/components/DateChipGroupRelative.vue"

export default {
    name: "IncidentEntityTypesTab",
    components: {
      IncidentEntityTypeCard,
      DateChipGroupRelative,
    },
    props: {
      entity_types: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedDateTime: 7,
      }
    },
    methods: {
      isDarkTheme() {
        return localStorage.getItem("dark_theme") === 'true';
      },
      onSelectedDateTimeChange(newValue) {
        this.selectedDateTime = newValue
      },
    },
  }
  </script>
