<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-app>
    <notification-snackbars-wrapper />
    <v-main>
        <v-app-bar app flat class="v-bar--underline" color="background0">
            <router-link :to="{ name: 'entities' }" style="text-decoration: none; margin-top: 5px;">
                <img src="/static/unstruct-logo-new.png" width="55" height="55"   />
            </router-link>
            <v-spacer />
            <!--Search Field-->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Entity Types ..."
                single-line
                hide-details
                clearable
                @click:clear="fetchEntityTypes"
                @keyup.enter="fetchEntityTypes"
            />
            <v-spacer />
            <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn small color="error--text" v-bind="attrs" v-on="on">
                <v-icon left class="mr-3">mdi-creation</v-icon>
                Unstr
                </v-btn>
            </template>
            <v-list>
                <v-list-item :to="{ name: 'entities' }">
                    <v-icon small left color="error--text">mdi-creation</v-icon>
                    <v-list-item-title>Entity-Centric Search</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'oldSchoolSearch' }">
                    <v-icon small left color="error--text">mdi-select-search</v-icon>
                    <v-list-item-title>Old School Search</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>

            </v-app-bar>
    <organization-banner />
    <incident-entity-types-tab
        :entity_types="entity_types"
    />
    <v-btn
        v-if="entity_types.length >= numItems"
        @click="fetchEntityTypes(false)"
        :loading="loading"
        :disabled="loading"
        color="primary"
        dark
        rounded
        class="mt-5 mb-5"
        elevation="2"
        block
    >
        <v-icon left>mdi-arrow-down</v-icon>
        Load More
    </v-btn>
    </v-main>
    </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import NotificationSnackbarsWrapper from "@/components/NotificationSnackbarsWrapper.vue"
import OrganizationBanner from "@/organization/OrganizationBanner.vue"
import IncidentEntitiesTab from "@/entity/IncidentEntitiesTab.vue"
import IncidentEntityCard from "@/entity/IncidentEntityCard.vue"
import EntityTypeApi from "@/entity_type/api"
import SearchUtils from "@/search/utils"
import IncidentEntityTypesTab from "@/entity_type/IncidentEntityTypesTab.vue"

export default {
    name: "EntityTypeEntryPage",
    props: {
    project: {
        type: String,
        default: null,
    },
    },

    components: {
        OrganizationBanner,
        NotificationSnackbarsWrapper,
        IncidentEntitiesTab,
        IncidentEntityCard,
        IncidentEntityTypesTab,
    },
    data() {
    return {
        loading: false,
        items: [],
        selected: {},
        currentPage: 1,
        entity_types: [],
        numItems: 10,
        itemsPerPageOptions: [5, 10, 25, 50, 100],
        search: "",
    }
    },
    computed: {
    ...mapFields("incident", ["selected.id"]),
    },
    created() {
        this.fetchEntityTypes(true)
    },
    methods: {
        fetchEntityTypes(fromSearch = true) {
            this.loading = true
            let filterOptions = {
                q: this.search,
                itemsPerPage: this.numItems,
                page: fromSearch ? 1 : this.currentPage,
            }

            if (this.project) {
                filterOptions = {
                ...filterOptions,
                filters: {
                    project: [this.project],
                },
                        }
                filterOptions = SearchUtils.createParametersFromTableOptions({ ...filterOptions })
            }

            EntityTypeApi.getAll(filterOptions).then((response) => {
                if (fromSearch) {
                    this.entity_types = response.data.items
                    this.currentPage = 1
                } else {
                    this.entity_types.push(...response.data.items)
                    this.currentPage++
                }
                this.loading = false
            }).catch((error) => {
                console.error(error)
                this.loading = false
            })
        },
    },
}
</script>
