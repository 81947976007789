<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-card elevation="1" outlined class="position-relative">
      <v-row>
        <v-col cols="8" style="align-self: center">
          <v-card-title class="pb-0 mb-0 secondary--text entity-value-header">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" @click="openEntityTab" style="cursor: pointer">
                  {{ truncateText(entity_type.name) }}
                </span>
              </template>
              <span>Click to view all entities of this type</span>
            </v-tooltip>
          </v-card-title>
        </v-col>
        <v-col class="text-right" cols="4" v-if="count > 1" style="align-self: center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-badge color="blue-grey" :content="badgeCount" overlap offset-x="45" offset-y="37">
                </v-badge>
              </span>
            </template>
            <span>This ADO type has been seen {{ count }} times across all IAOs</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-card-text style="font-size: 13px">
          <div class="grey--text">
            <v-icon small>mdi-update</v-icon>
            {{ entity_type.updated_at | formatRelativeDate }} &mdash; {{ entity_type.created_at | formatRelativeDate }}
          </div>
      </v-card-text>

      <v-divider></v-divider>
      <!--Entity occurrences chart card-->
      <entity-type-occurrences
        :entityTypeOccurrences="entity_type.occurrences"
        :entity_type="entity_type"
      />

     <!--Horizontal card that will display all the five cards below as clickable icons-->
     <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            outlined
            rounded="xl"
            class="d-flex align-center mx-4 mt-3 mb-3"
            :elevation="hover ? 2 : 0"
          >
            <v-card-text class="d-flex align-center">
                <v-progress-linear
                  v-if="isLoading"
                  height="5"
                  color="tips"
                  indeterminate
                ></v-progress-linear>
                <v-progress-linear
                  v-if="isLoading"
                  height="5"
                  color="success"
                  indeterminate
                ></v-progress-linear>
                <v-progress-linear
                  v-if="isLoading"
                  height="5"
                  color="warning"
                  indeterminate
                ></v-progress-linear>
                <v-progress-linear
                  v-if="isLoading"
                  height="5"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
                <v-progress-linear
                  v-if="isLoading"
                  height="5"
                  color="purple"
                  indeterminate
                ></v-progress-linear>


                <span v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openIncidentTab" class="ma-2">
                          <v-icon small v-if="incidentCount > 0" color="error">mdi-domain-plus</v-icon>
                          <v-icon small v-else>mdi-domain-off</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ incidentCount }} {{ incidentCount > 1 ? 'IAOs' : 'IAO' }}
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openCommitTab" class="ma-2">
                          <v-icon small v-if="commitCount > 0" color="error">mdi-source-branch-plus</v-icon>
                          <v-icon small v-else>mdi-api-off</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ commitCount }} {{ commitCount > 1 ? 'commits' : 'commit' }}
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openCaseTab" class="ma-2">
                          <v-icon small v-if="caseCount > 0" color="error">mdi-briefcase-plus-outline</v-icon>
                          <v-icon small v-else>mdi-briefcase-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ caseCount }} {{ caseCount > 1 ? 'OCOs' : 'OCO' }}
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openSignalInstanceTab" class="ma-2">
                          <v-icon small v-if="signalInstanceCount > 0" color="error">mdi-access-point-plus</v-icon>
                          <v-icon small v-else>mdi-signal-off</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ signalInstanceCount }} {{ signalInstanceCount > 1 ? 'ASOs' : 'ASO' }}
                    </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openTicketTab" class="ma-2">
                          <v-icon small v-if="ticketCount > 0" color="error">mdi-ticket-confirmation</v-icon>
                          <v-icon small v-else>mdi-card-bulleted-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ ticketCount }} {{ ticketCount > 1 ? 'tickets' : 'ticket' }}
                    </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-account-plus</v-icon>
                          <v-icon small v-else>mdi-account-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-cloud-outline</v-icon>
                          <v-icon small v-else>mdi-message-bulleted-off</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-video-outline</v-icon>
                          <v-icon small v-else>mdi-video-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-cloud-plus-outline</v-icon>
                          <v-icon small v-else>mdi-cloud-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-email-plus-outline</v-icon>
                          <v-icon small v-else>mdi-email-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn small @click="openPersonalDataTab" class="ma-2">
                          <v-icon small v-if="personalDataCount > 0" color="error">mdi-database-plus-outline</v-icon>
                          <v-icon small v-else>mdi-database-off-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>
                      {{ personalDataCount }} {{ personalDataCount > 1 ? 'personal data' : 'personal data' }}
                      </span>
                  </v-tooltip>

                </span>
              </v-card-text>
              <v-card-actions>
                <v-btn small
                  text
                  @click="openEntityTab"
                  class="text-capitalize"
                  style="font-size: 12px"
                  color="primary"
                >
                  View All Entities
                </v-btn>
              </v-card-actions>
           </v-card>
         </template>
      </v-hover>

      <!--Dialogs---->
      <v-dialog v-model="incidentDialog" max-width="1080">
          <incident-tab :inputIncidents="incidents" />
        </v-dialog>
      <v-dialog v-model="commitDialog" max-width="1080">
        <commit-tab :inputCommits="commits" />
      </v-dialog>
      <v-dialog v-model="signalDialog" max-width="1080">
        <signal-instance-tab :inputSignalInstances="signalInstances" />
      </v-dialog>
      <v-dialog v-model="caseDialog" max-width="1080">
        <case-tab :inputCases="cases" />
      </v-dialog>
      <v-dialog v-model="entityTabDialog" max-width="1080">
        <entity-tab :inputEntities=entity_type.entities />
      </v-dialog>
      <v-dialog v-model="ticketDialog" max-width="1080">
        <ticket-tab :inputTickets="tickets" />
      </v-dialog>
    </v-card>
  </template>

  <script>
  import { mapActions } from "vuex"
  import { mapFields } from "vuex-map-fields"

  import IncidentTab from "@/incident/IncidentTab.vue"
  import CommitTab from "@/commit/CommitTab.vue"
  import EntityTypeApi from "@/entity_type/api"
  import CaseTab from "@/case/CaseTab.vue"
  import SignalInstanceTab from "@/signal/SignalInstanceTab.vue"
  import EntityTypeOccurrences from "@/entity_type/EntityTypeOccurrences.vue"
  import EntityTab from "@/entity/EntityTab.vue"
  import TicketTab from "@/ticket/TicketTab.vue"

  export default {
    name: "IncidentEntityCard",
    props: {
      entity_type: {
        type: Object,
        required: true,
      },
      count: {
        type: Number,
        default: 1,
      },
      selectedDateTime: {
        type: Number,
        required: true,
      },
    },

    components: {
      "incident-tab": IncidentTab,
      "commit-tab": CommitTab,
      "case-tab": CaseTab,
      "signal-instance-tab": SignalInstanceTab,
      "entity-type-occurrences": EntityTypeOccurrences,
      "entity-tab": EntityTab,
      "ticket-tab": TicketTab,
    },
    computed: {
      ...mapFields("entity", ["dialogs.showIncidentView"]),
      ...mapFields("route", ["query"]),
      badgeCount() {
        return this.count >= 100 ? "x99+" : `x${this.count}`
      },
      isDarkTheme() {
        return localStorage.getItem("dark_theme") === 'true';
      },
      iconColor() {
      return this.isDarkTheme ? 'white--text' : 'black--text';
      },
      bgColor() {
        return this.isDarkTheme ? 'purple darken-5' : 'purple lighten-5';
      },
      lastOccurrence() {
        if (!this.entity_type.occurrences || this.entity_type.occurrences.length === 0) {
          return { occurred_at: null }
        }
        return this.entity_type.occurrences[this.entity_type.occurrences.length - 1]
      },
    },
    data() {
      return {
        incidentCount: null,
        caseCount: null,
        ticketCount: null,
        signalInstanceCount: null,
        entityTabDialog: false,
        commitCount: null,
        personalDataCount: null,
        isLoading: true,
        incidentDialog: false,
        commitDialog: false,
        caseDialog: false,
        signalDialog: false,
        ticketDialog: false,
        personalDataDialog: false,
        incidents: [],
        commits: [],
        tickets: [],
        cases: [],
        signalInstances: [],
        entities: [],
        cardBGColor: null,
      }
    },
    async mounted() {
      await this.refreshIncidentData();
      await this.refreshCommitData();
      await this.refreshData();
      await this.refreshTicketData();
    },
    watch: {
      selectedDateTime() {
        this.refreshIncidentData();
        this.refreshCommitData();
        this.refreshData();
        this.refreshTicketData();
      },
      isDarkTheme() {
        this.cardBGColor = this.bgColor;
      },
    },
    methods: {
      ...mapActions("query", ["copyToClipboardSuccess"]),
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          this.copyToClipboardSuccess()
        })
      },
      async refreshData() {
        try {
          this.isLoading = true

          const casePromise = EntityTypeApi.getCases(this.entity_type.id, this.selectedDateTime).then(
            (response) => response.data
          )
          const signalPromise = EntityTypeApi.getSignalInstances(
            this.entity_type.id,
            this.selectedDateTime
          ).then((response) => response.data)

          const [casesResponse, signalResponse] = await Promise.all([casePromise, signalPromise])

          this.cases = casesResponse.cases
          this.caseCount = this.cases.length

          this.signalInstances = signalResponse.instances
          this.signalInstanceCount = this.signalInstances.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshData:", error)
        }
      },
      async refreshIncidentData() {
        try {
          this.isLoading = true

          const incidentPromise = EntityTypeApi.getIncidents(this.entity_type.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [incidentsResponse] = await Promise.all([incidentPromise])

          this.incidents = incidentsResponse.incidents
          this.incidentCount = this.incidents.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshIncidentData:", error)
        }
      },
      async refreshCommitData() {
        try {
          this.isLoading = true

          const commitPromise = EntityTypeApi.getCommits(this.entity_type.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [commitsResponse] = await Promise.all([commitPromise])

          this.commits = commitsResponse.commits
          this.commitCount = this.commits.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshCommitData:", error)
        }
      },
      async refreshTicketData() {
        try {
          this.isLoading = true

          const ticketPromise = EntityTypeApi.getTickets(this.entity_type.id, this.selectedDateTime).then(
            (response) => response.data
          )

          const [ticketsResponse] = await Promise.all([ticketPromise])

          this.tickets = ticketsResponse.tickets
          this.ticketCount = this.tickets.length

          this.isLoading = false
        } catch (error) {
          console.error("Error in refreshTicketData:", error)
        }
      },
      async openCommitTab() {
        this.commitDialog = true
        this.$nextTick(() => {
          this.$refs.commitTab = this.commits
        })
      },
      async openIncidentTab() {
        this.incidentDialog = true
        this.$nextTick(() => {
          this.$refs.incidentTab = this.incidents
        })
      },
      async openSignalInstanceTab() {
        this.signalDialog = true
        this.$nextTick(() => {
          this.$refs.signalInstanceTab = this.signalInstances
        })
      },
      async openCaseTab() {
        this.caseDialog = true
        this.$nextTick(() => {
          this.$refs.caseTab = this.cases
        })
      },
      async openTicketTab() {
        this.ticketDialog = true
        this.$nextTick(() => {
          this.$refs.ticketTab = this.tickets
        })
      },
      async openPersonalDataTab() {
        this.personalDataDialog = true
        this.$nextTick(() => {
          this.$refs.personalDataTab = this.personalData
        })
      },
      openEntityTab() {
        this.entityTabDialog = true
        this.$nextTick(() => {
          this.$refs.entityTab = this.entity_type.entities
        })
      },
      getStartDate() {
        return new Date(Date.now() - this.selectedDateTime * 86400000).toISOString()
      },
      truncateText(text) {
        if (!text) {
          return ''
        }
        return text.length > 50 ? text.substring(0, 50) + "..." : text
      },
    },
  }
  </script>

  <style scoped>
  .entity-value-header {
    font-size: 20px;
  }

  .subtitle-section {
    font-size: 15px;
  }

  .subtitle-info {
    margin-bottom: 5px;
  }

  .subtitle-label {
    margin-right: 5px;
  }

  .subtitle-value {
    color: #757575;
  }

  </style>
```
